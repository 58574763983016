import * as React from "react"
import { Container } from "@chakra-ui/react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from "../components/breadcrumb"
import Categories from "../components/categories"
import WorkDetailsContent from "../components/workDetailsContent"
import Statics from "../components/statistics"
import workDetailData from "../json/workDetail/breadcrumb.json"

const WorkDetails = () => (
  <Layout>
    <Breadcrumb
      description={workDetailData.description}
      title={workDetailData.title}
      image={workDetailData.image}
    />
    <Container maxW={"full"} py="100px">
      <Categories />
      <WorkDetailsContent />
    </Container>
    <Statics />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default WorkDetails
