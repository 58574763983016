import React from "react"
import {
  Box,
  Text,
  Image,
  Tag,
  TagLabel,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react"
import workDetailData from "../../json/workDetail/workDetailsContent.json"

export default function WorkDetailsContent() {
  return (
    <Box>
      <Box py="50px">
        <Text as="h3" color="#04a073" fontWeight="600" fontSize="22px" mb="5">
          {workDetailData.title}
        </Text>
        <Tag size="lg" colorScheme="" borderRadius="full" p="0">
          <Image
            src="https://xord.solutions/wp-content/uploads/2022/06/Screenshot-from-2022-06-04-13-46-16-e1654506526805-300x140.png"
            name="Segun Adebayo"
            width="20px"
            mr="2"
          />
          <TagLabel fontSize="22px">{workDetailData.TagLabel}</TagLabel>
        </Tag>
      </Box>
      <Box pb="25px">
        <Text as="p" fontSize="20px">
          {workDetailData.desc}
        </Text>
      </Box>
      <Box pb="35px">
        <Text as="h2" fontSize="40px" fontWeight="700">
          {workDetailData.anotherTitle}
        </Text>
        <Text as="p" fontSize="20px">
          {workDetailData.anoherDesc} <br />
          <br /> {workDetailData.anotherSubDesc}
        </Text>
      </Box>
      <Box pt="25px">
        <Text as="h2" fontSize="40px" fontWeight="700">
          {workDetailData.bridgeText}{" "}
        </Text>
        <Box pt="35px">
          <Text as="h3" color="#04a073" fontWeight="600" fontSize="22px" mb="5">
            {workDetailData.bridgeText1}
          </Text>
          <Text as="p" fontSize="20px">
            {workDetailData.bridgeText1Desc}
          </Text>
          <Image
            py="15px"
            src="https://lh4.googleusercontent.com/B0a7z1uctaZQm-nK0Iegp5zVl6BjYMEl8itgczQU6BaNkwJyfQPRCYlw_W3qlBxukAPLgwYMV9eJQVUzqwuHgEbUyrao7ClYjpkfXrZu_d_Flgc-XI06XnhHjJpRpXzpL8IiVAiUL8M3LhZS3A"
          />
          <Text as="p" fontSize="20px">
            {workDetailData.bridgeText1SubDesc}
          </Text>
          <Image
            py="15px"
            src="https://lh4.googleusercontent.com/SdEWdOrHm7a68cfxb3LI2hzeKHnVpD9oqwu6upfbJbFE-K7qzSPVed_dsiOWe2qjZsgXcTue8lUD9jyL67IzwOS9XebOhEzLVZk98c7r8xOif0eqMKpn6nLD0bHGOU7oD8TPWxbgPur_eoe7yg"
          />
        </Box>
        <Box pt="35px">
          <Text as="h3" color="#04a073" fontWeight="600" fontSize="22px" mb="5">
            {workDetailData.bridgeText2}
          </Text>
          <Text as="p" fontSize="20px">
            {workDetailData.bridgeText2Desc}
          </Text>
          <UnorderedList pl="15px">
            <ListItem>
              <Text as="p" fontSize="20px">
                {workDetailData.listText1}
              </Text>
              <Image
                py="15px"
                src="https://lh6.googleusercontent.com/oUKCv_3xgEN0mwSZRgOiIc9nH0mIQXsXu168pLLfph3iPVIDKo5Mhqq-_v1JYhCgPn7FD_u-9_dmIUW4dk83-6KriWB3lsLCbs4YRLgiTvz8rwumnmaa0FSk1AMmapd76906u3_hKEpChJsbYQ"
              />
            </ListItem>
            <ListItem>
              <Text as="p" fontSize="20px">
                {workDetailData.listText2}
              </Text>
              <Image
                py="15px"
                src="https://lh6.googleusercontent.com/oUKCv_3xgEN0mwSZRgOiIc9nH0mIQXsXu168pLLfph3iPVIDKo5Mhqq-_v1JYhCgPn7FD_u-9_dmIUW4dk83-6KriWB3lsLCbs4YRLgiTvz8rwumnmaa0FSk1AMmapd76906u3_hKEpChJsbYQ"
              />
            </ListItem>
            <ListItem>
              <Text as="p" fontSize="20px">
                {workDetailData.listText3}
              </Text>
              <Image
                py="15px"
                src="https://lh6.googleusercontent.com/oUKCv_3xgEN0mwSZRgOiIc9nH0mIQXsXu168pLLfph3iPVIDKo5Mhqq-_v1JYhCgPn7FD_u-9_dmIUW4dk83-6KriWB3lsLCbs4YRLgiTvz8rwumnmaa0FSk1AMmapd76906u3_hKEpChJsbYQ"
              />
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>
      <Box pt="35px">
        <Text as="h2" fontSize="40px" fontWeight="700">
          {workDetailData.ConclusionText}
        </Text>

        <Text as="p" fontSize="20px">
          {workDetailData.ConclusionDesc}
        </Text>
      </Box>
    </Box>
  )
}
