import React from "react"
import {
  Box,
  chakra,
  Container,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from "@chakra-ui/react"
import { ReactNode } from "react"
import { BsPerson } from "react-icons/bs"
import { FiServer } from "react-icons/fi"
import { GoLocation } from "react-icons/go"
import staticsData from "../../json/workDetail/statics.json"

function StatsCard({ title, stat }) {
  return (
    <Stat px={{ base: 2, md: 4 }} py={"5"}>
      <Flex justifyContent="center">
        <Box pl={{ base: 2, md: 4 }} textAlign="center" color="white">
          <StatNumber fontSize={"66px"} fontWeight={"bold"}>
            {stat}
          </StatNumber>
          <StatLabel fontWeight={"medium"} fontSize={"20px"}>
            {title}
          </StatLabel>
        </Box>
      </Flex>
    </Stat>
  )
}

export default function Statics() {
  return (
    <Box
      bg="linear-gradient(180deg,#04a073,#00db9c 88.6%)"
      maxW="f"
      mx={"auto"}
      px={{ base: 2, sm: 12, md: 17 }}
      py="120px"
    >
      <Container maxW={"full"}>
        <SimpleGrid columns={{ base: 1, md: 3 }}>
          {staticsData.StatsCard.map(item => {
            return (
              <StatsCard key={item.id} title={item.title} stat={item.stats} />
            )
          })}
          {/* <StatsCard title={"Binance side migration"} stat={"20"} />
          <StatsCard title={"Ethereum side Migration"} stat={"17"} /> */}
        </SimpleGrid>
      </Container>
    </Box>
  )
}
